/* ---------------------------------------------------
    LIST STYLE
----------------------------------------------------- */
li, li::marker {
    color: #FA0000 !important;
}

/* ---------------------------------------------------
    LINK STYLE
----------------------------------------------------- */
a {
    color: #FA5000 !important
}

button {
    a {
        color: white !important
    }
}


/* ---------------------------------------------------
    TEXT STYLE
----------------------------------------------------- */
.text-primary {
    color: #FA5000 !important
}

.white-space-break-spaces {
    white-space: break-spaces !important;
}

/* ---------------------------------------------------
    INPUT STYLE
----------------------------------------------------- */
input:focus, input:focus-within, input:focus-visible {
    border-color: #FA5000 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 1px #FA5000 !important;
}

.form-control:focus, .form-select:focus {
    border-color: #FA5000 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 1px #FA5000 !important;
}

.input-group:focus-within {
    outline: 1px solid #FA5000 !important;
}

.input-group:focus-within *:focus {
    border-color: transparent !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0px #FA5000 !important;
}

/* ---------------------------------------------------
    REACT SELECT STYLE
----------------------------------------------------- */
.css-1nmdiq5-menu {
    z-index: 2000 !important;
}

/* ---------------------------------------------------
    MANTINE CHIPS STYLE
----------------------------------------------------- */
.mantine-Chips-iconWrapper {
    display: none !important;
}

.mantine-Chips-label:hover, .mantine-Chips-checked {
    background: #00AB66 !important;
    border-color: #00AB66 !important;
    color: white !important;
}

/* ---------------------------------------------------
    MANTINE DATEPICKER STYLE
----------------------------------------------------- */
.mantine-DatePicker-icon {
    svg {
        color: black !important;
    }
}

/* ---------------------------------------------------
    MANTINE MODAL STYLE
----------------------------------------------------- */
.mantine-Modal-close {
    align-self: flex-start !important;

    svg {
        width: 30px !important;
        height: 30px !important;
    }
}

@media only screen and (max-width: 768px) {
    .mantine-Paper-root {
        width: 100% !important;
    }
}

/* ---------------------------------------------------
    MANTINE BUTTON STYLE
----------------------------------------------------- */
.mantine-Button-filled:disabled {
    background-color: #FA5000 !important;
    color: white !important;
    opacity: 0.5 !important;
}

/* ---------------------------------------------------
    MANTINE TABLE STYLE
----------------------------------------------------- */
.mantine-Table-root {
    thead {
        tr {
            th {
                top: -1px !important;
            }
        }
    }
}

.mantine-3ga1wp.mantine-Table-root tr td:first-of-type {
    left: -1px !important;
}

/* ---------------------------------------------------
    SWEET ALERT BUTTON STYLE
----------------------------------------------------- */
.swal2-styled.swal2-default-outline:focus, .swal2-styled.swal2-default-outline:focus-visible {
    box-shadow: none !important;
}

/* ---------------------------------------------------
    BOOKING PAGE STYLE
----------------------------------------------------- */
.booking-title {
    color: #1D2939 !important;
}

.booking-image {
    img {
        height: 300px !important;
        object-fit: fill !important;
    }

    @media only screen and (max-width: 768px) {
        img {
            height: auto !important;
        }
    }
}

/* ---------------------------------------------------
    SCHEDULER PAGE STYLE
----------------------------------------------------- */
.scheduler-table {
    div:nth-child(2) {
        @media only screen and (max-width: 576px) {
            z-index: 1000 !important;
        }
    }
}

/* ---------------------------------------------------
    PADDING STYLE
----------------------------------------------------- */
.ps-lg-2-point-5 {
    @media (min-width: 992px) {
        padding-left: 0.75rem !important;
    }
}

/* ---------------------------------------------------
    WIDTH STYLE
----------------------------------------------------- */
.w-xs-100 {
    @media only screen and (max-width: 600px) {
        width: 100% !important;
    }
}

/* ---------------------------------------------------
    FLEX STYLE
----------------------------------------------------- */
.flex-xs-wrap {
    @media only screen and (max-width: 600px) {
        flex-wrap: wrap !important;
    }
}

/* ---------------------------------------------------
    PROGRESSBAR STYLE
----------------------------------------------------- */
.progressbar{
    width: 65% !important;
    height: 15px !important;
    border-radius: 30px !important;
    border: 1px solid #D8D8D8 !important;
    margin-bottom: 10px !important;
    text-align: center !important;
    background-color: #D8D8D8 !important;
}

.progressbarfill{
    height: 100% !important;
    border-radius: 30px !important;
    background-color: #FA5000 !important;
    transition: width 0.5 ease-out !important;
    color: #fff !important;
}

.facility-image {
    img {
        height: 300px !important;
        object-fit: fill !important;
    }

    @media only screen and (max-width: 768px) {
        img {
            height: auto !important;
        }
    }
}

.play-store-image {
    top: 87% !important;
    left: 10% !important;

    @media only screen and (max-width: 1250px) {
        width: 15vw !important;
        left: 11% !important;
    }

    @media only screen and (min-width: 1440px) {
        left: 14% !important;
    }
}

.app-store-image {
    top: 87% !important;
    left: 27% !important;

    @media only screen and (max-width: 1250px) {
        width: 15vw !important;
        left: 29% !important;
    }

    @media only screen and (min-width: 1440px) {
        left: 30% !important;
    }
}